import React from 'react';
import {Edit} from 'react-admin';

import TGNotificationsForm from './TGNotificationsForm';
import TGNotificationsTitle from './TGNotificationsTitle';
import PessimisticMutationMode from '../../components/admin/PessimisticMutationMode';
import {RoutePath} from '../../routes';

export const TGNotificationsEdit = (props) => (
  <PessimisticMutationMode
    Component={Edit}
    Form={TGNotificationsForm}
    routePath={RoutePath.TG_NOTIFICATIONS_SETTINGS_SHOW}
    title={<TGNotificationsTitle/>}
    {...props}/>
);
