import React from 'react';
import { connect } from 'react-redux';
import { CheckboxGroupInput, showNotification, withTranslate } from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import styles from './TGNotificationsForm.css';

import TelemedicService from '../../services/TelemedicService';
import { getAuthToken } from '../../storages/auth';
import { ContractStatus, NotificationType } from '../../constants';
import { formatNotificationType, formatPersonName } from '../../utils/formatters';
import { ValueTypes } from '../../utils/values';

class TGNotificationsForm extends React.Component {
  constructor(props) {
    super(props);
    const { params, translate } = this.props;
    this.state = {
      organizations: [],
      organizationsToNotify: [],
      submitProgress: false,
      organizations: [],
      workers: props.record ? [{id: props.record.worker_id, name: formatPersonName(props.record.worker)}] : [],
      formInitialValues: (params) || {
        notification_types: []
      }
    };
    this.service = new TelemedicService(getAuthToken());

    this.service.getOrganizationValues().then((response) => {
      this.setState({ organizations: response.json.data });
    }).catch((e) => {
      console.log(e);
    });

    this.notificationTypeChoices = Object.values(NotificationType).map((notificationType) => (
      { id: notificationType, name: formatNotificationType(notificationType).toLowerCase() }
    ));

    this.defaultChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];

    this.organizationsChoiceMenu = [
      {
        label: translate('telemedic.usage.form.selectAll'),
        filter: (list) => list,
      },
      {
        label: translate('telemedic.usage.form.selectWithContract'),
        filter: (list) => list.filter((i) => i['contract_status'] === ContractStatus.CONCLUDED),
      },
      {
        label: translate('telemedic.usage.form.selectNone'),
        filter: (list) => [],
      },
    ];
    this.setWorkers = this.setWorkers.bind(this)
    this.setOrganizationsToNotify = this.setOrganizationsToNotify.bind(this)

    if (props.record.worker_id) {
      this.setOrganizationsToNotify(props.record.worker_id);
    }
  }

  onSubmit = (data) => {
    const { showNotification } = this.props;
    this.setState({ submitProgress: true });
    const {
      organizations, notification_types,
    } = data;
    this.service.setTGNotifications(organizations, notification_types)
      .then(() => {
        this.setState({ submitProgress: false });
        window.history.back()
      }).catch((e) => {
        console.log(e);
        this.setState({ submitProgress: false });
        showNotification('telemedic.errors.submitError');
      });
  }

  setWorkers(organizationId) {
    this.service.getWorkerValues(null, organizationId).then((response) => {
      this.setState({ workers: response.json.data });
    }).catch((e) => {
      console.log(e);
    });
  }

  setOrganizationsToNotify(workerId) {
    this.service.getWorkerById(workerId).then((response) => {
      this.setState({ organizationsToNotify: response.json.data.attributes.organizations });
    }).catch((e) => {
      console.log(e);
    });
  }

  render() {
    const { ...rest } = this.props;
    const { organizations, organizationsToNotify, workers } = this.state;

    return (
      <RestrictedSimpleForm {...rest}>
        {
          !rest.record.id && (
            <AutocompleteInput
              label="telemedic.tgNotifications.form.organization"
              choices={organizations}
              choiceLabelField="name"
              choiceValueField="id"
              source="organization_id"
              onChange={this.setWorkers}
              required
            />
          )
        }
        <AutocompleteInput
          label="telemedic.tgNotifications.form.worker"
          choices={workers}
          choiceLabelField="name"
          choiceValueField="id"
          source="worker_id"
          onChange={this.setOrganizationsToNotify}
          required
        />
        <CheckboxGroupInput
          className={styles.formControl}
          source="notification_types"
          label="telemedic.tgNotifications.form.notificationTypes"
          choices={this.notificationTypeChoices}
          row={false}
        />
        <LargeCheckListInput
          className={styles.formControl}
          source="organization_ids"
          label="telemedic.tgNotifications.form.organizationsToNotify"
          choices={organizationsToNotify}
          choiceLabelField="name"
          choiceValueField="id"
          valueType={ValueTypes.INTEGER}
          choiceMenu={this.organizationsChoiceMenu}
          fixedWidth
        />
      </RestrictedSimpleForm>
    );
  }
}

export default connect(null, { showNotification })(withTranslate(TGNotificationsForm));
