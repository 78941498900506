import React, { useCallback, useEffect, useState } from 'react';
import { Title, useTranslate } from 'react-admin';

import moment from 'moment';

import Button from '@material-ui/core/Button';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import SecurityIcon from '@material-ui/icons/Security';
import Typography from '@material-ui/core/Typography';
import UsbIcon from '@material-ui/icons/Usb';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import TelegramIcon from '@material-ui/icons/Telegram';
import QRCode from "react-qr-code";

import useInterval from '../../hooks/useInterval';
import useTelemedicService from '../../hooks/useTelemedicService';
import styles from './Dashboard.module.css';

import { Role } from '../../constants';
import { RoutePath, navigateTo } from '../../routes';
import { getUserInfo, isTokenAttached } from '../../storages/auth';
import {
  formatPersonName, formatRole, formatDisplayDate, formatDisplayTime,
} from '../../utils/formatters';
import { replace } from '../../utils/text';

const Dashboard = () => {
  const translate = useTranslate();
  const service = useTelemedicService();

  const [userInfo, setUserInfo] = useState(null);
  const [documents, setDocuments] = useState(null);
  useEffect(() => {
    setUserInfo(getUserInfo());
    service.getUserInfo()
      .then((response) => setUserInfo(response.json.data))
      .catch((e) => console.log(e));
    service.getDocuments()
      .then((response) => setDocuments(response.json.data))
      .catch((e) => console.log(e));
  }, [service]);

  const greeting = replace(translate('telemedic.dashboard.greeting'), {
    '{userName}': userInfo ? formatPersonName(userInfo) : '',
  });
  const role = replace(translate('telemedic.dashboard.role'), {
    '{role}': userInfo ? formatRole(userInfo.role) : '',
  });
  const organizations = replace(translate('telemedic.dashboard.organizations'), {
    '{organizations}': userInfo ? userInfo.organizations.length : 0,
  });

  const attachToken = useCallback(() => navigateTo(RoutePath.ATTACH_TOKEN), []);
  const attachEsiaToProfile = useCallback(async () => {
    const response = await service.getEsiaLogin();    
    window.location.href = response.json.redirect_to;
  }, [service]);
  const unlinkEsiaFromProfile = useCallback(async () => {
    await service.unlinkEsiaFromProfile();
    service.getUserInfo()
      .then((response) => {
        setUserInfo(response.json.data)
      })
      .catch((e) => console.log(e));
  }, [service]);
  const changePassword = useCallback(() => navigateTo(RoutePath.PASSWORD_CHANGE), []);
  const openDocument = useCallback((event) => {
    window.location.href = event.currentTarget.getAttribute('data-href');
  }, []);
  const followTGLink = () => {
    window.open(userInfo.tg_deeplink, '_blank')
  }
  
  return (
    <Paper className={styles.dashboard}>
      <Title title="telemedic.dashboard.title" />
      <div className={styles.info}>
        <Clock />
        <div className={styles.userInfo}>
          <Typography variant="h6">{greeting}</Typography>
          <Typography variant="subtitle1">{role}</Typography>
          {userInfo && userInfo.role !== Role.ADMIN && (
            <Typography variant="subtitle1">{organizations}</Typography>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          color="primary"
          onClick={attachToken}
          startIcon={<UsbIcon />}
          variant="contained"
        >
          {
            isTokenAttached()
              ? translate('telemedic.actions.replaceToken')
              : translate('telemedic.actions.attachToken')
          }
        </Button>
        {userInfo && userInfo.role === Role.USER && (
          <Button
            color="primary"
            onClick={changePassword}
            startIcon={<SecurityIcon />}
            variant="contained"
          >
            {translate('telemedic.actions.changePassword')}
          </Button>
        )}
        {
          userInfo && userInfo.is_allowed_to_auth_with_esia && !userInfo.esia_id && (
            <Button
              color="primary"
              onClick={attachEsiaToProfile}
              startIcon={<SyncIcon />}
              variant="contained"
            >
              {
                translate('telemedic.actions.attachEsiaToProfile')
              }
            </Button>
          )
        } 
        {
          userInfo && userInfo.is_allowed_to_auth_with_esia && userInfo.esia_id && (
            <Button
              color="primary"
              onClick={unlinkEsiaFromProfile}
              startIcon={<SyncDisabledIcon />}
              variant="contained"
            >
              {
                translate('telemedic.actions.unlinkEsiaFromProfile')
              }
            </Button>
          )
        }
      </div>
      {documents && documents.length && (
        <div className={styles.documents}>
          <Typography variant="h5">{translate('telemedic.dashboard.documents')}</Typography>
          <List>
            {documents.map((document) => (
              <ListItem
                data-href={document.url}
                key={document.url}
                onClick={openDocument}
                button
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={document.name} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
      {
        userInfo && userInfo.is_allowed_to_receive_tg_notifications && userInfo.tg_deeplink && (
          <div className={styles.tgContainer}>
            <div className={styles.tgColumn}>
              {
                translate('telemedic.dashboard.scanQRCode')
              }
              <QRCode
                size={150}
                className={styles.tgLinkContent}
                value={userInfo.tg_deeplink}
                viewBox={`0 0 150 150`}
              />
            </div>
            <div className={styles.tgColumn}>
              {
                translate('telemedic.dashboard.followTGButton')
              }
              <Button
                color="primary"
                onClick={followTGLink}
                startIcon={<TelegramIcon className={styles.tgIcon}/>}
                className={styles.tgLinkContent}
                variant="contained"
              />
            </div>
          </div>
        )
      }
    </Paper>
  );
};

const Clock = () => {
  const [time, setTime] = useState();
  const intervalCallback = useCallback(() => {
    setTime(moment());
  }, []);
  useInterval(intervalCallback, 1000, true);
  return (
    <div className={styles.clock}>
      <Typography variant="h3">{formatDisplayTime(time)}</Typography>
      <Typography variant="subtitle1">{formatDisplayDate(time)}</Typography>
    </div>
  );
};

export default Dashboard;
