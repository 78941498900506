import React from 'react';
import { Route } from 'react-router-dom';

import ActiveUsersPage from './pages/activeUsers';
import BillingSumPage from './pages/billingSum';
import CheckupsImportPage from './pages/checkupsImport';
import CheckupJournalsPage from './pages/checkupJournals/CheckupJournalsPage';
import Dashboard from './pages/dashboard';
import DiskSpacePage from './pages/diskSpace';
import HealthStatusPage from './pages/healthStatus';
import MedicEfficiencyPage from './pages/medicEfficiency';
import MedicWorkloadPage from './pages/medicWorkload';
import PasswordChangePage from './pages/auth/PasswordChangePage';
import PasswordResetPage from './pages/auth/passwordReset/PasswordResetPage';
import ReceivedCheckupsPage from './pages/receivedCheckups';
import SignedCheckupJournalsPage from './pages/signedCheckupJournals';
import StatisticsCheckupPage from './pages/statisticsCheckups';
import StatisticsTechPage from './pages/statisticsTech';
import TechInspectionJournalsPage from './pages/techInspectionJournals/TechInspectionJournalsPage';
import TerminalsMap from './pages/terminals/TerminalsMap';
import TGNotificationsForm from './pages/tg_notifications_settings/TGNotificationsForm';
import UnderConstruction from './pages/common/UnderConstruction';
import UsageCheckupPage from './pages/usageCheckup';
import UsageTechPage from './pages/usageTech';
import UsersImportPage from './pages/usersImport';
import VehiclesImportPage from './pages/vehiclesImport';
import WaybillsMovementPage from './pages/waybillsMovement/WaybillsMovementPage';
import TestResultLivePage from './pages/testResults/TestResultLivePage';
import withRestriction from './hocs/withRestriction';
import { AttachTokenPage, EsiaLoginPage, EsiaLogoutPage } from './pages/auth';
import { AlcoholSmsReport, CheckOrgInnPage } from './pages/organizations';
import { CheckupLivePage } from './pages/checkups';
import { MedicEcpDisplay, TerminalVerificationDisplay } from './pages/control';
import { FeedbackForm } from './pages/feedback';
import { TechInspectionLivePage } from './pages/techInspections'
import { Section } from './permissions';
import { serializeQueryParams } from './utils/urls';
import TerminalStatusDisplay from './pages/control/TerminalStatusDisplay';
import { SmsReportDisplay } from './pages/control/SmsReportDisplay';

export const RoutePath = {
  ACTIVE_USERS: '/active_users',
  ATTACH_TOKEN: '/attach_token',
  ALCOHOL_SMS_REPORT: '/sms_alcohol_report',
  AIS_ERP_INTEGRATION: '/ais_erp_integration',
  BILLING_SUM: '/billing_sum',
  CHECK_ORG_INN: '/check_org_inn',
  CHECKUPS: '/checkups',
  CHECKUPS_IMPORT: '/checkups_import',
  CHECKUP_JOURNALS: '/checkup_journals',
  CHECKUP_SHOW: '/checkups/:id/show',
  CHECKUPS_LIVE: '/checkups_live',
  CONTROL_MEDIC_ECP: '/medic_ecp',
  CONTROL_TERMINAL_VERIFICATION: '/terminal_verification',
  CONTROL_TERMINAL_STATUS: '/terminal_status',
  DASHBOARD: '/',
  DEALER_BALANCE: '/dealer_balance',
  DEALER_PAYMENTS: '/dealer_payments',
  DEALER_PAYMENT_CREATE: '/dealer_payments/create',
  DEALER_PAYMENT_SHOW: '/dealer_payments/:id/show',
  DEALERS: '/dealers',
  DEALER_SHOW: '/dealers/:id/show',
  DEALERS_RESPONSIBLE: '/dealers_responsible',
  DEALERS_RESPONSIBLE_SHOW: '/dealers_responsible/:id/show',
  DISK_SPACE: '/disk_space',
  ESIA_LOGIN: '/login/esia',
  ESIA_LOGOUT: '/logout/esia',
  FEEDBACK_FORM: '/feedback_form',
  HEALTH_STATUS: '/health_status',
  INSPECTION_POINTS: '/inspection_points',
  INSPECTION_POINT_SHOW: '/inspection_points/:id/show',
  LOGIN: '/login',
  LOGS: '/logs',
  USER_HEALTH_STATUS: '/health_status/:userId',
  MED_ORGANIZATIONS: '/med_organizations',
  MED_ORGANIZATION_SHOW: '/med_organizations/:id/show',
  MEDIC_EFFICIENCY: '/medic_efficiency',
  MEDIC_WORKLOAD: '/medic_workload',
  ORG_BALANCE: '/org_balance',
  ORG_PAYMENTS: '/org_payments',
  ORG_PAYMENT_CREATE: '/org_payments/create',
  ORG_PAYMENT_SHOW: '/org_payments/:id/show',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_SHOW: '/organizations/:id/show',
  PASSWORD_CHANGE: '/auth/password_change',
  PASSWORD_RESET: '/password_reset',
  PARTNER_EPL: '/partner_epl',
  PERIODIC_MAILINGS: '/periodic_mailings',
  PERIODIC_MAILINGS_SHOW: '/periodic_mailings/:id/show',
  RECEIVED_CHECKUPS: '/received_checkups',
  SIGNED_CHECKUP_JOURNALS: '/signed_checkup_journals',
  STATISTICS_CHECKUP: '/statistics_checkup',
  STATISTICS_TECH: '/statistics_tech',
  SMS_REPORT: '/sms_report',
  TECH_INSPECTIONS_LIVE: '/tech_inspections_live',
  TECH_INSPECTIONS: '/tech_inspections',
  TECH_INSPECTION_SHOW: '/tech_inspections/:id/show',
  TECH_INSPECTION_EDIT: '/tech_inspections/:id/edit',
  TECH_INSPECTION_JOURNALS: '/tech_inspection_journals',
  TERMINALS: '/terminals',
  TERMINALS_MAP: '/terminals/map',
  TERMINAL_SHOW: '/terminals/:id/show',
  TEST_QUESTION_CATEGORIES: '/test_question_categories',
  TEST_QUESTION_CATEGORIES_SHOW: '/test_question_categories/:id/show',
  TEST_RESULTS: '/test_results',
  TEST_RESULTS_SHOW: 'test_results/:id/show',
  TEST_RESULTS_LIVE: '/test_results_live',
  TG_NOTIFICATIONS_SETTINGS: '/tg_notifications_settings',
  TG_NOTIFICATIONS_SETTINGS_SHOW: '/tg_notifications_settings/:id/show',
  USAGE_CHECKUP: '/usage_checkup',
  USAGE_TECH: '/usage_tech',
  USEFUL_LINKS: '/useful_links',
  USERS: '/users',
  USERS_IMPORT: '/users/import',
  USER_SHOW: '/users/:id/show',
  VEHICLES: '/vehicles',
  VEHICLES_IMPORT: '/vehicles/import',
  VEHICLE_SHOW: '/vehicles/:id/show',
  WAYBILLS: '/waybills',
  WAYBILL_SHOW: '/waybills/:id/show',
  EWAYBILLS: '/ewaybills',
  EWAYBILL_SHOW: '/ewaybills/:id/show',
  WAYBILLS_MOVEMENT: '/waybillsMovement',
  WORKERS: '/workers',
  WORKER_SHOW: '/workers/:id/show',
  EXTERNAL_LINK: '/external_link',
  ACTION: '/action',
};

export const getUrl = (routePath, args, queryArgs) => {
  let routePathWithReplacements = routePath;
  if (args) {
    Object.keys(args).forEach((key) => {
      routePathWithReplacements = routePathWithReplacements.replace(`:${key}`, args[key]);
    });
  }
  const query = queryArgs ? `?${serializeQueryParams(queryArgs)}` : '';
  return `/#${routePathWithReplacements}${query}`;
};

export const navigateTo = (routePath, args, queryArgs) => {
  const url = getUrl(routePath, args, queryArgs);
  if (url) {
    window.location.href = url;
  }
};

export default [
  <Route exact path={RoutePath.ACTIVE_USERS} component={
    withRestriction(ActiveUsersPage, Section.ACTIVE_USERS)
  }/>,
  <Route exact path={RoutePath.ATTACH_TOKEN} component={AttachTokenPage} noLayout />,
  <Route
    exact
    path={RoutePath.ALCOHOL_SMS_REPORT}
    component={withRestriction(AlcoholSmsReport, Section.ALCOHOL_SMS_REPORT)}
  />,
  <Route
    exact
    path={RoutePath.CHECK_ORG_INN}
    component={withRestriction(CheckOrgInnPage, Section.CHECK_ORG_INN)}
  />,
  <Route
    exact
    path={RoutePath.SIGNED_CHECKUP_JOURNALS}
    component={withRestriction(SignedCheckupJournalsPage, Section.SIGNED_CHECKUP_JOURNALS)}
  />,
  <Route
    exact
    path={RoutePath.CHECKUPS_LIVE}
    component={withRestriction(CheckupLivePage, Section.CHECKUPS)}
  />,
  <Route
    exact
    path={RoutePath.CONTROL_MEDIC_ECP}
    component={withRestriction(MedicEcpDisplay, Section.MEDIC_ECP_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.CONTROL_TERMINAL_VERIFICATION}
    component={withRestriction(TerminalVerificationDisplay, Section.TERMINAL_VERIFICATION_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.SMS_REPORT}
    component={withRestriction(SmsReportDisplay, Section.SMS_REPORT)}
  />,
  <Route 
    exact
    path={RoutePath.CONTROL_TERMINAL_STATUS}
    component={withRestriction(TerminalStatusDisplay, Section.TERMINAL_STATUS)}
  />,
  <Route
    exact
    path={RoutePath.DASHBOARD}
    component={withRestriction(Dashboard)}
  />,
  <Route
    exact
    path={RoutePath.ESIA_LOGIN}
    component={EsiaLoginPage}
    noLayout
  />,
  <Route
    exact
    path={RoutePath.ESIA_LOGOUT}
    component={EsiaLogoutPage}
    noLayout
  />,
  <Route
    exact
    path={RoutePath.HEALTH_STATUS}
    component={withRestriction(HealthStatusPage, Section.HEALTH_STATUS_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.USER_HEALTH_STATUS}
    component={withRestriction(HealthStatusPage, Section.HEALTH_STATUS_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.MEDIC_EFFICIENCY}
    component={withRestriction(MedicEfficiencyPage, Section.MEDIC_EFFICIENCY_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.MEDIC_WORKLOAD}
    component={withRestriction(MedicWorkloadPage, Section.MEDIC_WORKLOAD_REPORTS)}
  />,
  <Route
    exact
    path={RoutePath.STATISTICS_CHECKUP}
    component={withRestriction(StatisticsCheckupPage, Section.STATISTICS_CHECKUP)}
  />,
  <Route
    exact
    path={RoutePath.STATISTICS_TECH}
    component={withRestriction(StatisticsTechPage, Section.STATISTICS_TECH)}
  />,
  <Route
    exact
    path={RoutePath.TERMINALS_MAP}
    component={withRestriction(TerminalsMap, Section.TERMINALS_MAP)}
  />,
  <Route
    exact
    path={RoutePath.TECH_INSPECTIONS_LIVE}
    component={withRestriction(TechInspectionLivePage, Section.TECH_INSPECTIONS)}
  />,
  <Route
    exact
    path={RoutePath.USAGE_CHECKUP}
    component={withRestriction(UsageCheckupPage, Section.USAGE_CHECKUP)}
  />,
  <Route
    exact
    path={RoutePath.USAGE_TECH}
    component={withRestriction(UsageTechPage, Section.USAGE_TECH)}
  />,
  <Route
    exact
    path={RoutePath.BILLING_SUM}
    component={withRestriction(BillingSumPage, Section.BILLING_SUM)}
  />,
  <Route
    exact
    path={RoutePath.RECEIVED_CHECKUPS}
    component={withRestriction(ReceivedCheckupsPage, Section.RECEIVED_CHECKUPS_REPORTS)}
  />,
  <Route exact path={RoutePath.FEEDBACK_FORM} component={FeedbackForm} />,
  <Route exact path={RoutePath.LOGS} component={UnderConstruction} />,
  <Route exact path={RoutePath.USEFUL_LINKS} component={UnderConstruction} />,
  <Route exact path={RoutePath.USERS_IMPORT} component={UsersImportPage} />,
  <Route exact path={RoutePath.VEHICLES_IMPORT} component={VehiclesImportPage} />,
  <Route exact path={RoutePath.CHECKUPS_IMPORT} component={CheckupsImportPage} />,
  <Route
    exact
    path={RoutePath.TEST_RESULTS_LIVE}
    component={withRestriction(TestResultLivePage, Section.TEST_RESULTS)}
  />,
  <Route
    exact
    path={RoutePath.WAYBILLS_MOVEMENT}
    component={withRestriction(WaybillsMovementPage, Section.WAYBILLS_MOVEMENT)}
  />,
  <Route
    exact
    path={RoutePath.TECH_INSPECTION_JOURNALS}
    component={withRestriction(TechInspectionJournalsPage, Section.TECH_INSPECTION_JOURNALS)}
  />,
  <Route
    exact
    path={RoutePath.CHECKUP_JOURNALS}
    component={withRestriction(CheckupJournalsPage, Section.CHECKUP_JOURNALS)}
  />,
  <Route exact path={RoutePath.PASSWORD_RESET} component={PasswordResetPage} noLayout />,
  <Route exact path={RoutePath.PASSWORD_CHANGE} component={PasswordChangePage} noLayout />,
  <Route exact path={RoutePath.DISK_SPACE} component={withRestriction(DiskSpacePage, Section.DISK_SPACE)} />,
];
