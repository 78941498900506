import React, { useCallback } from 'react';
import {
  Filter, Datagrid, TextField, SelectInput, TextInput,
  downloadCSV, useDataProvider, useListContext, useListController, ListContextProvider
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import { ContractStatus, Role } from '../../constants';
import { getRole } from '../../storages/auth';
import { enumChoices } from '../../utils/enums';
import { formatContractStatus } from '../../utils/formatters';

const OrganizationList = (props) => {
  const userIsAdmin = (getRole() === Role.ADMIN);
  const dataProvider = useDataProvider();

  const partnerFormatter = useCallback(
    (partner) => `${partner.last_name} ${partner.first_name} ${partner.middle_name} (${partner.login})`,
    [],
  );

  const exporter = async () => {
    const { data } = await dataProvider.getList('organizations', {
      pagination: { page: 1, perPage: 5000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    });

    const organizationsForExport = data.map(organization => ({
      id: organization.id,
      inn: organization.inn,
      name: organization.name,
      region: organization.region,
      contract_status: organization.contract_status,
      dealer: organization.dealer?.name || '',
      partner: organization.partner ? partnerFormatter(organization.partner) : ''
    }));

    jsonExport(organizationsForExport, {}, (err, csv) => {
      downloadCSV(csv, 'organizations');
    });
  };

  return (
    <RestrictedList
      title="telemedic.organizations.label"
      filters={<OrganizationFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      exporter={exporter}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <TextField
          label="telemedic.organizations.list.inn"
          source="inn"
          sortable
        />
        <TextField
          label="telemedic.organizations.list.name"
          source="name"
          sortable
        />
        <TextField
          label="telemedic.organizations.list.region"
          source="region"
          sortable
        />
        <TextField
          label="telemedic.organizations.list.contract"
          source="contract_status"
          sortable
        />
        <TextField
          label="telemedic.organizations.list.dealer"
          source="dealer.name"
          sortable
          sortBy="dealer"
        />
        {userIsAdmin && (
          <FormatValueField
            label="telemedic.organizations.list.partner"
            formatter={partnerFormatter}
            source="partner"
            sortable={false}
          />
        )}
      </Datagrid>
    </RestrictedList>
  );
};

const OrganizationFilter = (props) => {
  const service = useTelemedicService();
  const dealers = useTelemedicServiceJsonValue(
    () => service.getDealerValues(), null, [],
  );
  return (
    <Filter key="organization_filter" {...props}>
      <TextInput
        label="telemedic.organizations.list.name"
        source="name"
        alwaysOn
        resettable
      />
      <SelectInput
        label="telemedic.organizations.list.contract"
        source="contract_status"
        choices={enumChoices(ContractStatus, formatContractStatus)}
        alwaysOn
        resettable
      />
      <AutocompleteInput
        label="telemedic.organizations.list.dealer"
        choiceLabelField="name"
        choiceValueField="id"
        choices={dealers}
        source="dealer_id"
        alwaysOn
        resettable
      />
    </Filter>
  );
};

export default OrganizationList;
