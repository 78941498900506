import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  useRefresh, SimpleShowLayout, BooleanField, TextField, useNotify, useShowController
} from 'react-admin';

import { Box, Button } from '@material-ui/core';
import RestrictedShow from '../../components/admin/RestrictedShow';
import LabeledTextField from '../../components/fields/LabeledTextField';
import FormatValueField from '../../components/fields/FormatValueField';
import PartnerEplTitle from './PartnerEplTitle';
import styles from './PartnerEplShow.module.css';
import moment from 'moment';

import { Role, DISPLAY_DATETIME_FORMAT } from '../../constants';
import { getRole } from '../../storages/auth';
import { RoutePath, getUrl } from '../../routes';
import { formatPersonName } from '../../utils/formatters';
import useTelemedicService from '../../hooks/useTelemedicService';

const PartnerEplShow = (props) => {
  const { record } = useShowController(props);
  const permission = (getRole() === Role.ADMIN) || (getRole() === Role.SUPPORT);
  const { showNotification } = { props };
  const refresh = useRefresh();
  const formatDisplayDateTime = (date) => {
    return date ? moment(date).format(DISPLAY_DATETIME_FORMAT) : '';
  };
  const service = useTelemedicService();

  const handleUnlinkCheckup = () => {
    service.unlinkPartnerEplCheckup(record.id).then(() => {
      refresh();
    });
  };

  const handleRereadCheckup = () => {
    service.rereadPartnerEplCheckup(record.id);
  };

  return (
    <RestrictedShow
      title={<PartnerEplTitleWithControls showNotification={showNotification} permission={permission} />}
      {...props}
    >
      <SimpleShowLayout>
        {record && record.organization && (
          <LabeledTextField
            label="telemedic.partnerEpl.show.organization"
            source="organization.name"
            record={record}
            url={getUrl(RoutePath.ORGANIZATION_SHOW, { id: record.organization.id })}
          />
        )}
        {record && record.driver && (
          <LabeledTextField
            label="telemedic.partnerEpl.show.driver"
            source="driver"
            record={record}
            url={getUrl(RoutePath.USER_SHOW, { id: record.driver.id })}
            formatter={formatPersonName}
          />
        )}
        
        <div className="ra-field">
          <div className="ra-field-label">
            <span>Медосмотр</span>
          </div>
          {record?.checkup_id && (
          <div className="ra-field-value">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <LabeledTextField
                  source="checkup_id"
                  record={record}
                  url={getUrl(RoutePath.CHECKUP_SHOW, { id: record.checkup_id })}
                />
              </Box>
              {!record?.uploaded && (
                <Box ml={2}>
                  <Button 
                    size="small" 
                    color="primary" 
                    variant="contained" 
                    style={{ marginRight: 8 }}
                    onClick={handleUnlinkCheckup}
                  >
                    Отвязать медосмотр
                  </Button>
                  <Button 
                    size="small" 
                    color="secondary" 
                    variant="contained"
                    onClick={handleRereadCheckup}
                  >
                    Провести медосмотр заново
                  </Button>
                </Box>
              )}
            </Box>
          </div>
          )}
        </div>
        <TextField
          label="telemedic.partnerEpl.show.t1File"
          source="t1_file"
        />
        <TextField
          label="telemedic.partnerEpl.show.t2File"
          source="t2_file"
        />
        <TextField
          label="telemedic.partnerEpl.show.t5File"
          source="t5_file"
        />
        <TextField
          label="telemedic.partnerEpl.show.t6File"
          source="t6_file"
        />
        <TextField
          label="telemedic.partnerEpl.show.newTitulFilename"
          source="new_titul_filename"
        />
        <TextField
          label="telemedic.partnerEpl.show.uidPlId"
          source="uid_pl_id"
        />
        <TextField
          label="telemedic.partnerEpl.show.partnerDocId"
          source="partner_doc_id"
        />
        <FormatValueField
          label="telemedic.partnerEpl.show.dateEpl"
          source="date_epl"
          formatter={formatDisplayDateTime}
        />
        <BooleanField
          label="telemedic.partnerEpl.show.uploaded"
          source="uploaded"
        />
        <TextField
          label="telemedic.partnerEpl.show.error"
          source="error"
        />
      </SimpleShowLayout>
    </RestrictedShow>
  );
};

const PartnerEplTitleWithControls = ({ record }) => {
  const iframeRef = useRef(null);

  const notify = useNotify();
  const [notice, setNotice] = useState();
  const [noticeType, setNoticeType] = useState();

  useEffect(() => {
    if (notice && noticeType) {
      notify(notice, noticeType);
      setNotice();
      setNoticeType();
    }
  }, [notice, noticeType, notify]);

  return (
    <>
      <PartnerEplTitle record={record} />
      <iframe
        className={styles.iframe}
        ref={iframeRef}
        title="print"
      />
    </>
  );
};

export default PartnerEplShow;
