export class Permissions {
  constructor(sections) {
    this.sections = sections;
  }

  check(section, action) {
    return this.sections && this.sections[section] && this.sections[section].indexOf(action) >= 0;
  }
}

export const Section = {
  ACTIVE_USERS: 'ACTIVE_USERS',
  AIS_ERP_INTEGRATION: 'AIS_ERP_INTEGRATION',
  BILLING_SUM: 'BILLING_SUM',
  ALCOHOL_SMS_REPORT: 'ALCOHOL_SMS_REPORT',
  CHECK_ORG_INN: 'CHECK_ORG_INN',
  CHECKUPS: 'CHECKUPS',
  CHECKUP_JOURNALS: 'CHECKUP_JOURNALS',
  DEALER_BALANCE: 'DEALER_BALANCE',
  DEALER_PAYMENTS: 'DEALER_PAYMENTS',
  DEALERS: 'DEALERS',
  DEALERS_RESPONSIBLE: 'DEALERS_RESPONSIBLE',
  HEALTH_STATUS_REPORTS: 'HEALTH_STATUS_REPORTS',
  INSPECTION_POINTS: 'INSPECTION_POINTS',
  KIS_ART_WORKERS: 'KIS_ART_WORKERS',
  MED_ORGANIZATIONS: 'MED_ORGANIZATIONS',
  MEDIC_ECP_REPORTS: 'MEDIC_ECP_REPORTS',
  MEDIC_EFFICIENCY_REPORTS: 'MEDIC_EFFICIENCY_REPORTS',
  MEDIC_WORKLOAD_REPORTS: 'MEDIC_WORKLOAD_REPORTS',
  ORG_BALANCE: 'ORG_BALANCE',
  ORG_PAYMENTS: 'ORG_PAYMENTS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  PARTNER_EPL: 'PARTNER_EPL',
  PERIODIC_MAILINGS: 'PERIODIC_MAILINGS',
  RECEIVED_CHECKUPS_REPORTS: 'RECEIVED_CHECKUPS_REPORTS',
  SERVER_MONITORING: 'SERVER_MONITORING',
  SIGNED_CHECKUP_JOURNALS: 'SIGNED_CHECKUP_JOURNALS',
  STATISTICS_CHECKUP: 'STATISTICS_CHECKUP',
  STATISTICS_TECH: 'STATISTICS_TECH',
  SMS_REPORT: 'SMS_REPORT',
  TECH_INSPECTIONS: 'TECH_INSPECTIONS',
  TECH_INSPECTION_JOURNALS: 'TECH_INSPECTION_JOURNALS',
  TERMINALS: 'TERMINALS',
  TERMINALS_MAP: 'TERMINALS_MAP',
  TERMINAL_MONITORING: 'TERMINAL_MONITORING',
  TERMINAL_VERIFICATION_REPORTS: 'TERMINAL_VERIFICATION_REPORTS',
  TERMINAL_STATUS: 'TERMINAL_STATUS',
  TEST_QUESTION_CATEGORIES: 'TEST_QUESTION_CATEGORIES',
  TEST_RESULTS: 'TEST_RESULTS',
  TG_NOTIFICATIONS_SETTINGS: 'TG_NOTIFICATIONS_SETTINGS',
  USAGE_CHECKUP: 'USAGE_CHECKUP',
  USAGE_TECH: 'USAGE_TECH',
  USERS: 'USERS',
  VEHICLES: 'VEHICLES',
  WAYBILLS: 'WAYBILLS',
  EWAYBILLS: 'EWAYBILLS',
  WAYBILLS_MOVEMENT: 'WAYBILLS_MOVEMENT',
  WORKERS: 'WORKERS',
  DISK_SPACE: 'DISK_SPACE',
};

export const Action = {
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  REVIEW: 'REVIEW',
  SIGN: 'SIGN',
  BAN: 'BAN',
  IMPORT: 'IMPORT',
  RESTART: 'RESTART',
};

export default Permissions;
