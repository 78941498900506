import React, {
  useCallback, useMemo, useRef, useState, useEffect,
} from 'react';
import {
  SimpleShowLayout, TextField, useNotify, useTranslate, useRefresh, useShowController
} from 'react-admin';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';
import { Box, Button } from '@material-ui/core';
import DropDownMenu from '../../components/DropDownMenu';
import FormatValueField from '../../components/fields/FormatValueField';
import LabeledTextField from '../../components/fields/LabeledTextField';
import GridShowMediator from '../../components/GridShowMediator';
import QrCodeField from '../../components/fields/QrCodeField';
import ProgressButton from '../../components/ProgressButton';
import RestrictedShow from '../../components/admin/RestrictedShow';
import EWaybillTitle from './EWaybillTitle';
import useTelemedicService from '../../hooks/useTelemedicService';
import { getRutokenServiceInstance } from '../../services/RutokenService';
import styles from './EWaybillShow.module.css';
import { printHtml } from '../../utils/print';

import { Role } from '../../constants';
import { getRole } from '../../storages/auth';
import { RoutePath, getUrl } from '../../routes';
import {
  formatPersonName, formatWaybillTerm, formatWhoCreated,
  formatPostshiftCheckup, formatTransportType, formatInfoAboutTypeTransportation,
} from '../../utils/formatters';

const EWaybillShow = (props) => {
  const { record } = useShowController(props);
  const formatVehicle = (vehicle) => (
    `${vehicle.manufacturer} ${vehicle.model} (${vehicle.car_license_plate})`
  );
  const permission = (getRole() === Role.ADMIN) || (getRole() === Role.DISPATCHER);
  const supportPermission = (getRole() === Role.ADMIN) || (getRole() === Role.SUPPORT);
  const { showNotification } = {props};
  const refresh = useRefresh();
  const service = useTelemedicService();
  const handleUnlinkCheckup = () => {
    service.unlinkEwaybillCheckup(record.id).then(() => {
      refresh();
    });
  };
  const handleRereadCheckup = () => {
    service.rereadEwaybillCheckup(record.id);
  };
  const handleUnlinkTechInspection = () => {
    service.unlinkEwaybillTechInspectionCheckup(record.id).then(() => {
      refresh();
    });
  };
  const handleRereadTechInspection = () => {
    service.rereadEwaybillTechInspectionCheckup(record.id);
  };
  return (
    <RestrictedShow
      title={<EWaybillTitleWithControls showNotification={showNotification} permission={permission} />}
      {...props}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <TextField
              label="telemedic.ewaybills.show.organization"
              source="organization.name"
            />
            <FormatValueField
              label="telemedic.ewaybills.show.driver"
              source="driver"
              formatter={formatPersonName}
            />
            <div className="ra-field">
              <div className="ra-field-label">
                <span>Медосмотр</span>
              </div>
              {record?.checkup_id && supportPermission && (
              <div className="ra-field-value">
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <LabeledTextField
                      source="checkup_id"
                      record={record}
                      url={getUrl(RoutePath.CHECKUP_SHOW, { id: record.checkup_id })}
                    />
                  </Box>
                  {record?.signature && !record?.tech_inspection_id && (
                    <Box ml={2}>
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        style={{ marginRight: 8 }}
                        onClick={handleUnlinkCheckup}
                      >
                        Отвязать медосмотр
                      </Button>
                      <Button 
                        size="small" 
                        color="secondary" 
                        variant="contained"
                        onClick={handleRereadCheckup}
                      >
                        Провести медосмотр заново
                      </Button>
                    </Box>
                  )}
                </Box>
              </div>
              )}
            </div>
            <div className="ra-field">
              <div className="ra-field-label">
                <span>Техосмотр</span>
              </div>
              {record?.tech_inspection_id && supportPermission && (
              <div className="ra-field-value">
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <LabeledTextField
                      source="tech_inspection_id"
                      record={record}
                      url={getUrl(RoutePath.TECH_INSPECTION_SHOW, { id: record.tech_inspection_id })}
                    />
                  </Box>
                  {!record?.qr_photo && (
                    <Box ml={2}>
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        style={{ marginRight: 8 }}
                        onClick={handleUnlinkTechInspection}
                      >
                        Отвязать техосмотр
                      </Button>
                      <Button 
                        size="small" 
                        color="secondary" 
                        variant="contained"
                        onClick={handleRereadTechInspection}
                      >
                        Провести техосмотр заново
                      </Button>
                    </Box>
                  )}
                </Box>
              </div>
              )}
            </div>
            <FormatValueField
              label="telemedic.ewaybills.show.vehicle"
              source="vehicle"
              formatter={formatVehicle}
            />
            <FormatValueField
              label="telemedic.ewaybills.show.postshiftCheckup"
              source="postshift_checkup"
              formatter={formatPostshiftCheckup}
            />
            <FormatValueField
              label="telemedic.ewaybills.show.transportType"
              source="transport_type"
              formatter={formatTransportType}
            />
            <FormatValueField
              label="telemedic.ewaybills.show.infoAboutTypeTransportation"
              source="infoAbout_type_transportation"
              formatter={formatInfoAboutTypeTransportation}
            />
            <FormatValueField
              label="telemedic.ewaybills.show.waybillTerm"
              source="waybill_term"
              formatter={formatWaybillTerm}
            />            
            <FormatValueField
              label="telemedic.ewaybills.show.whoCreated"
              source="who_created"
              formatter={formatWhoCreated}
            />
            <h5>Должностное лицо</h5>
            <TextField
              label="telemedic.ewaybills.show.fio"
              source="fio"
            />
            <TextField
              label="telemedic.ewaybills.show.jobTitle"
              source="job_title"
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <h5>Данный QR-код требуется для инспекторов ГАИ</h5>
          <QrCodeField
            label="telemedic.ewaybills.show.QRCode"
            source="qr_photo"
          />
          <h5>Сообщения об ошибках если они есть:</h5>
          <TextField
              label="telemedic.ewaybills.show.error"
              source="error"
              style={{ color: 'red' }}
          />
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

const EWAYBILL_QR = 4;

const EWaybillTitleWithControls = ({ record, showNotification, permission }) => {
  const translate = useTranslate();
  const iframeRef = useRef(null);
  const service = useTelemedicService();
  const rutokenService = getRutokenServiceInstance();

  const [printProgress, setPrintProgress] = useState();
  const printOptions = useMemo(() => {
    const printWaybill = (promise) => {
      setPrintProgress(true);
      promise.then((response) => {
        printHtml(iframeRef, response.text);
        setPrintProgress(false);
      }).catch((e) => {
        console.log(e);
        setPrintProgress(false);
        showNotification(e.errorDescription || translate('telemedic.errors.submitError'));
      });
    };
    return [
      {
        action: () => printWaybill(service.getEwaybillPrintPage(record.id, EWAYBILL_QR)),
        id: 'id_print_common_waybill',
        labelId: 'telemedic.ewaybills.show.QRCode'
      },
    ]
  }, [record, service, showNotification, translate]);

  const [disable, setDisable] = useState(false);
  const notify = useNotify();
  const [notice, setNotice] = useState();
  const [noticeType, setNoticeType] = useState();
  useEffect(() => {
    if (notice && noticeType) {
      notify(notice, noticeType);
      setNotice();
      setNoticeType();
      if (noticeType === 'warning') {
        setDisable(false);
      }
    }
  }, [notice, noticeType, notify]);
  const sendWaybill = useCallback(() => {
    console.log(record.organization.epl_gis_epd_api);
    setDisable(true);
    notify(translate('telemedic.ewaybills.show.sendGisEpdBegin'), 'info', '', undefined, 1000);
    const signJournals = (deviceId, certificateId, certificatePublicKey) => {
      let encodedJournal = null;
      return service.getEncodedEwaybillXml(record.id, certificatePublicKey).then((response) => {
        encodedJournal = response.json.data;
        return rutokenService.sign(deviceId, certificateId, encodedJournal);
      }).then((signature) => {
        console.log(signature)
        return service.uploadSignedEwaybillXml(record.id, encodedJournal, signature);
      });
    }
    const _signCheckupIfNeeded = (deviceId, certificateId) => {
      return rutokenService.getCertificatePublicKey(deviceId, certificateId)
          .then((certificatePublicKey) => signJournals(deviceId, certificateId, certificatePublicKey));
    }
    const _withDeviceAndCertificateId = (promiseFactory) => {
      return rutokenService.getDeviceAndCertificateId()
        .catch((error) => {
          service.unsignEWaybill(record.id);
          setNotice(translate('telemedic.ewaybills.show.sendGisEpdError'));
          setNoticeType('warning');
          console.log(error);
          return Promise.reject({
            errorTextId: 'telemedic.checkupDialogs.tokenErrorText',
            error,
          });
        })
        .then((data) => promiseFactory(data));
    }
    if (record.organization.epl_gis_epd_api) {
      if (rutokenService.checkDeviceAvailability()) {
        return _withDeviceAndCertificateId(({deviceId, certificateId}) => {
          return _signCheckupIfNeeded(deviceId, certificateId)
          .then(() => service.signEWaybill(record.id))
          .then(() => {
            setNotice(translate('telemedic.ewaybills.show.sendGisEpdSuccess'));
            setNoticeType('info')});
        }).catch(() => {
          service.unsignEWaybill(record.id);
          setNotice(translate('telemedic.ewaybills.show.sendGisEpdError'));
          setNoticeType('warning');
        });
      }
    }
  }, [record, service, translate, notify, rutokenService]);
  const createTechInspection = useCallback(() => {
    setDisable(true);
    return service.createTechInspection(record.id).then(notify(translate('telemedic.ewaybills.show.techInspectionSuccessfullyCreated'), 'info'));
  }, [record, service, notify, translate])
  if (record.signature !== 'ok' && permission) {
    return (
      <>
        <EWaybillTitle record={record} />
        <ProgressButton
          className={styles.printButton}
          variant="contained"
          color="primary"
          icon={<CloudUploadIcon />}
          label="telemedic.ewaybills.show.sendGisEpd"
          onClick={sendWaybill}
          disabled={disable}
        />
        <iframe
          className={styles.iframe}
          ref={iframeRef}
          title="print"
        />
      </>
    );
  } else if (record.post_trip_tech_inspection_id === 0 && permission) {
    // Если record.post_trip_tech_inspection_id === 0, рендерим кнопку создания техосмотра и печати
    return (
      <>
        <EWaybillTitle record={record} />
        <ProgressButton
          className={styles.printButton}
          variant="contained"
          color="primary"
          icon={<CloudUploadIcon />}
          label="telemedic.ewaybills.show.createTechInspection"
          onClick={createTechInspection}
          disabled={disable}
        />
        <DropDownMenu
          choices={printOptions}
          icon={<PrintIcon />}
          labelId="telemedic.actions.print"
          progress={printProgress}
        />
        <iframe
          className={styles.iframe}
          ref={iframeRef}
          title="print"
        />
      </>
    );
  } else if (record.qr_photo !== '') {
    // Если record.signature === 'ok', рендерим только печать
    return (
      <>
        <EWaybillTitle record={record} />
        <DropDownMenu
          choices={printOptions}
          icon={<PrintIcon />}
          labelId="telemedic.actions.print"
          progress={printProgress}
        />
        <iframe
          className={styles.iframe}
          ref={iframeRef}
          title="print"
        />
      </>
    );
  } else {
    // Если record.signature === 'ok', рендерим только печать
    return (
      <>
        <EWaybillTitle record={record} />
        <iframe
          className={styles.iframe}
          ref={iframeRef}
          title="print"
        />
      </>
    );
  }
};

export default EWaybillShow;
