import React, {useCallback} from 'react';
import {Filter, Datagrid, TextField} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import FormatValueField from '../../components/fields/FormatValueField';

export const TGNotificationsList = props => {
  const workerFormatter = useCallback((worker) => (
    `${worker.last_name} ${worker.first_name} ${worker.middle_name} (${worker.login})`
  ), []);

  const organizationsFormatter = useCallback((organizations) => organizations.length, []);

  return(
    <RestrictedList
      title="telemedic.tgNotifications.label"
      filters={<TGNotificationsFilter/>}
      sort={{field: 'id', order: 'DESC'}}
      {...props}>
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable={true}/>
        <FormatValueField
          label="telemedic.tgNotifications.list.worker"
          source="worker"
          formatter={workerFormatter}
        />
        <FormatValueField
          label="telemedic.tgNotifications.list.organizations"
          source="organizations"
          formatter={organizationsFormatter}
        />
      </Datagrid>
    </RestrictedList>
  );
}

const TGNotificationsFilter = (props) => (
    <Filter key="tg_notifications_settings_filter" {...props}>
      <TelemedicDataSource
        dataPromise={organizationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        alwaysOn resettable>
        <AutocompleteInput
          label="telemedic.tgNotifications.list.organization"
          choiceLabelField="name"
          choiceValueField="id"
          source="organization_id"/>
      </TelemedicDataSource>
      <TelemedicDataSource
        dataPromise={workersPromise}
        dataDefault={[]}
        dataAttrName="choices"
        alwaysOn resettable>
        <AutocompleteInput
          label="telemedic.tgNotifications.list.worker"
          choiceLabelField="name"
          choiceValueField="id"
          source="worker_id"/>
      </TelemedicDataSource>
    </Filter>
);

const organizationsDataPromise = (service) => {
  return service.getOrganizationValues();
}
const workersPromise = (service) => {
  return service.getWorkerValues();
};
