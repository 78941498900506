import React from 'react';
import {
  Filter, Datagrid, TextField, useTranslate,
} from 'react-admin';

import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedList from '../../components/admin/RestrictedList';
import FormatValueField from '../../components/fields/FormatValueField';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {
  formatDisplayDateTime, formatWaybillTerm, formatWhoCreated, formatOrg, formatPersonName
} from '../../utils/formatters';

const PartnerEplList = (props) => (
  <RestrictedList
    title="telemedic.partnerEpl.label"
    filters={<PartnerEplFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <TextField
        label="telemedic.partnerEpl.list.organization"
        source="organization.name"
        sortable
        sortBy="organization.id"
      />
      <FormatValueField
        label="telemedic.partnerEpl.list.driver"
        source="driver"
        sortable
        sortBy="driver.id"
        formatter={formatPersonName}
      />
      <FormatValueField
        label="telemedic.partnerEpl.list.dateEpl"
        source="date_epl"
        formatter={formatDisplayDateTime}
      />
      <SyncFilesField />
      <SyncUploadField />
    </Datagrid>
  </RestrictedList>
);

const PartnerEplFilter = (props) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const orgs = useTelemedicServiceJsonValue(
    () => service.getOrganizationValues(), null, [],
  );
  const drivers = useTelemedicServiceJsonValue(
    () => service.getUserValues(), null, [],
  );
  const statuses = [
    { id: 1, name: translate('telemedic.partnerEpl.list.statuses.success') },
    { id: 0, name: translate('telemedic.partnerEpl.list.statuses.error') },
  ];
  return (
    <Filter key="partnerEplFilter" {...props}>
      {/* <DateInput
        label="telemedic.partnerEpl.list.dateEplFrom"
        source="date_epl_from"
        alwaysOn
        minWidth
        resettable
      />
      <DateInput
        label="telemedic.partnerEpl.list.dateEplTo"
        source="date_epl_to"
        alwaysOn
        minWidth
        resettable
      /> */}
      <AutocompleteInput
        label="telemedic.partnerEpl.list.orgs"
        choices={orgs}
        choiceLabelField="name"
        choiceValueField="id"
        source="org_id"
        alwaysOn
        minWidth
        resettable
      />
      {/* <AutocompleteInput
        label="telemedic.partnerEpl.list.drivers"
        choices={drivers}
        choiceLabelField="name"
        choiceValueField="id"
        source="driver_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.partnerEpl.list.uploaded"
        choices={statuses}
        choiceLabelField="name"
        choiceValueField="id"
        source="uploaded"
        alwaysOn
        minWidth
        resettable
      /> */}
    </Filter>
  );
};

const SyncFilesField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    const hasT2 = Boolean(record.t2_file);
    const hasT6 = Boolean(record.t6_file);

    if (!hasT2 && !hasT6) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={translate('telemedic.partnerEpl.list.noFiles')}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    } else if (hasT2 || hasT6) {
      const presentFile = hasT2 ? 'T2' : 'T6';
      return (
        <Tooltip disableFocusListener disableTouchListener title={`${translate('telemedic.partnerEpl.list.hasFile')} ${presentFile}`}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

const SyncUploadField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    if (record.uploaded) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={translate('telemedic.partnerEpl.list.uploaded')}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip disableFocusListener disableTouchListener title={translate('telemedic.partnerEpl.list.notUploaded')}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

export default PartnerEplList;
