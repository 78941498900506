import React from 'react';
import {
  Filter, Datagrid, TextField, useTranslate,
  useDataProvider, downloadCSV
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Tooltip from '@material-ui/core/Tooltip';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import RestrictedList from '../../components/admin/RestrictedList';
import FormatValueField from '../../components/fields/FormatValueField';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {
  formatDisplayDateTime, formatWaybillTerm, formatWhoCreated, formatOrg
} from '../../utils/formatters';

const AisErpList = (props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const exporter = async () => {
    const { data } = await dataProvider.getList('ais_erp_integration', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'datetime_send', order: 'DESC' },
      filter: {}
    });

    const aisErpForExport = data.map(record => ({
      id: record.id,
      organization: record.organization?.name || '',
      terminal: record.terminal?.name || '',
      datetime_send: formatDisplayDateTime(record.datetime_send),
      status: record.error 
        ? `${translate('telemedic.aisErpIntegration.list.error')} - ${record.error}`
        : record.ais_erp_checkup_id 
          ? translate('telemedic.aisErpIntegration.list.statuses.success')
          : translate('telemedic.aisErpIntegration.list.statuses.pending')
    }));

    jsonExport(aisErpForExport, {}, (err, csv) => {
      downloadCSV(csv, 'ais_erp_integration');
    });
  };

  return (
    <RestrictedList
      title="telemedic.aisErpIntegration.label"
      filters={<AisErpFilter />}
      sort={{ field: 'datetime_send', order: 'DESC' }}
      exporter={exporter}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <TextField
          label="telemedic.aisErpIntegration.list.organization"
          source="organization.name"
          sortable
          sortBy="organization"
        />
        <TextField
          label="telemedic.aisErpIntegration.list.terminal"
          source="terminal.name"
          sortable
          sortBy="terminal"
          // formatter={formatTerminal}
        />
        <FormatValueField
          label="telemedic.aisErpIntegration.list.datetimeSend"
          source="datetime_send"
          formatter={formatDisplayDateTime}
        />
        <SyncStatusField />
      </Datagrid>
    </RestrictedList>
  );
};

const AisErpFilter = (props) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const orgs = useTelemedicServiceJsonValue(
    () => service.getOrganizationValues(), null, [],
  );
  const terminals = useTelemedicServiceJsonValue(
    () => service.getTerminalValues(), null, [],
  );
  const statuses = [
    { id: 1, name: translate('telemedic.aisErpIntegration.list.statuses.success') },
    { id: 0, name: translate('telemedic.aisErpIntegration.list.statuses.error') },
  ];
  return (
    <Filter key="ais_erp_integration_filter" {...props}>
      <DateInput
        label="telemedic.aisErpIntegration.list.sendDateFrom"
        source="send_date_from"
        alwaysOn
        minWidth
        resettable
      />
      <DateInput
        label="telemedic.aisErpIntegration.list.sendDateTo"
        source="send_date_to"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.organizations"
        choices={orgs}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.terminals"
        choices={terminals}
        choiceLabelField="name"
        choiceValueField="id"
        source="terminal_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.status"
        choices={statuses}
        choiceLabelField="name"
        choiceValueField="id"
        source="status"
        alwaysOn
        minWidth
        resettable
      />
    </Filter>
  );
};

const SyncStatusField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    if (record.error) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={`${translate('telemedic.aisErpIntegration.list.error')} - ${record.error}`}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    }
    if (record.ais_erp_checkup_id) {
      const formattedTime = record.datetime_send ? formatDisplayDateTime(record.datetime_send) : '-'
      const sentAt = `${translate('telemedic.aisErpIntegration.list.datetimeSend')}: ${formattedTime}`;
      return (
        <Tooltip disableFocusListener disableTouchListener title={sentAt}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

export default AisErpList;
