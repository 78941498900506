import React from 'react';
import {useTranslate} from 'react-admin';

const TGNotificationsTitle = ({record}) => {
  const typeName = useTranslate()('telemedic.tgNotifications.show.label');
  return (
    <span>{
      (record && record.worker)
      ? `${typeName} ${record.worker.last_name} ${record.worker.first_name} ${record.worker.middle_name}`
      : typeName
    }</span>
  );
};

export default TGNotificationsTitle;
