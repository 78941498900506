import React, {useEffect, useState} from 'react';
import {
  BooleanInput, NumberInput, SelectInput, TextInput, required,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DeleteableFileInput from '../../components/inputs/DeleteableFileInput';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import MaskedDateInput from '../../components/inputs/MaskedDateInput';
import PhoneInput from '../../components/inputs/PhoneInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import TelemedicDataSource from '../../components/TelemedicDataSource';

import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import { Role, Sex } from '../../constants';
import { getRole } from '../../storages/auth';
import { enumChoices } from '../../utils/enums';
import { formatSex } from '../../utils/formatters';
import { ValueTypes } from '../../utils/values';

const UserForm = ({ getInputProps, ...rest }) => {
  const role = getRole();
  const showIndividualParameters = (role !== Role.DISPATCHER);
  const showDiagnosis = (role === Role.ADMIN || role === Role.MEDIC);
  const service = useTelemedicService();
  const testQuestionCategories = useTelemedicServiceJsonValue(
    () => service.getTestQuestionCategoryValues(), null, [],
  );
  const [aisErpApi, setAisErpApi] = useState(false);
  const userOrg = useTelemedicServiceJsonValue(
    () => {
      if (!rest.record?.organization?.id) {
        return Promise.resolve(null);
      }
      return service.getOrganization(rest.record.organization.id);
    }, 
    null, 
    [],
  );
  
  useEffect(() => {
    if (userOrg?.attributes) {
      setAisErpApi(userOrg.attributes.ais_erp_api);
    }
  }, [userOrg]);
  return (
    <RestrictedSimpleForm {...rest}>
      <TextInput
        label="telemedic.common.kisArtId"
        source="kis_art_id"
        {...getInputProps('kis_art_id')}
      />
      <TextInput
        label="telemedic.users.form.lastName"
        source="last_name"
        validate={required()}
        {...getInputProps('last_name')}
      />
      <TextInput
        label="telemedic.users.form.firstName"
        source="first_name"
        validate={required()}
        {...getInputProps('first_name')}
      />
      <TextInput
        label="telemedic.users.form.middleName"
        source="middle_name"
        validate={required()}
        {...getInputProps('middle_name')}
      />
      <SelectInput
        label="telemedic.users.form.sex"
        source="sex"
        choices={enumChoices(Sex, formatSex)}
        validate={required()}
        {...getInputProps('sex')}
      />
      <TextInput
        label="telemedic.users.form.password"
        source="password"
        {...getInputProps('password')}
      />
      <BooleanInput
        label="telemedic.users.form.isAllowedToAuthWithEsia"
        source="is_allowed_to_auth_with_esia"
        {...getInputProps('is_allowed_to_auth_with_esia')}
      />
      <TextInput
        label="telemedic.users.form.esiaId"
        source="esia_id"
        {...getInputProps('esia_id')}
      />
      <TelemedicDataSource
        dataPromise={organizationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
        validate={required()}
      >
        <AutocompleteInput
          label="telemedic.users.form.organization"
          choiceLabelField="name"
          choiceValueField="id"
          source="organization_id"
          fullWidth
          {...getInputProps('organization_id')}
        />
      </TelemedicDataSource>
      <TextInput
        label="telemedic.users.form.personnelNumber"
        source="personnel_number"
        {...getInputProps('personnel_number')}
      />
      <TextInput
        label="telemedic.users.form.cardId"
        source="card_id"
        {...getInputProps('card_id')}
      />
      <TextInput
        label="telemedic.users.form.position"
        source="position"
        {...getInputProps('position')}
      />
      <MaskedDateInput
        label="telemedic.users.form.dateHired"
        source="date_hired"
        {...getInputProps('date_hired')}
      />
      <MaskedDateInput
        label="telemedic.users.form.dateFired"
        source="date_fired"
        {...getInputProps('date_fired')}
      />
      <PhoneInput
        label="telemedic.users.form.phone"
        source="phone"
        {...getInputProps('phone')}
      />
      <TextInput
        label="telemedic.users.form.drivingLicenseSerial"
        source="driving_license_serial"
        {...getInputProps('driving_license_serial')}
      />
      <TextInput
        label="telemedic.users.form.drivingLicenseNumber"
        source="driving_license_number"
        {...getInputProps('driving_license_number')}
      />
      <MaskedDateInput
        label="telemedic.users.form.drivingLicenseDate"
        source="driving_license_date"
        {...getInputProps('driving_license_date')}
      />
      <TextInput
        label="telemedic.users.form.passport"
        source="passport"
        {...getInputProps('passport')}
      />
      <MaskedDateInput
        label="telemedic.users.form.passportDate"
        source="passport_date"
        {...getInputProps('passport_date')}
      />
      <TextInput
        label="telemedic.users.form.passportBy"
        source="passport_by"
        {...getInputProps('passport_by')}
      />
      <TextInput
        label="telemedic.users.form.snils"
        source="snils"
        {...getInputProps('snils')}
      />
      <TextInput
        label="telemedic.users.form.licenseCardNumber"
        source="license_card_number"
        {...getInputProps('license_card_number')}
      />
      <MaskedDateInput
        label="telemedic.users.form.licenseCardDateStart"
        source="license_card_date_start"
        {...getInputProps('license_card_date_start')}
      />
      <MaskedDateInput
        label="telemedic.users.form.licenseCardDateEnd"
        source="license_card_date_end"
        {...getInputProps('license_card_date_end')}
      />
      <MaskedDateInput
        label="telemedic.users.form.birthday"
        source="birthday"
        validate={required()}
        {...getInputProps('birthday')}
      />
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.minArterialPressure"
            source="min_arterial_pressure"
            initialValue="100"
            {...getInputProps('min_arterial_pressure')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.maxArterialPressure"
            source="max_arterial_pressure"
            initialValue="150"
            {...getInputProps('max_arterial_pressure')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.minVenousPressure"
            source="min_venous_pressure"
            initialValue="60"
            {...getInputProps('min_venous_pressure')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.maxVenousPressure"
            source="max_venous_pressure"
            initialValue="100"
            {...getInputProps('max_venous_pressure')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.minPulse"
            source="min_pulse"
            initialValue="54"
            {...getInputProps('min_pulse')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.maxPulse"
            source="max_pulse"
            initialValue="96"
            {...getInputProps('max_pulse')}
          />
        )
      }
      {
        showIndividualParameters && (
          <NumberInput
            label="telemedic.users.form.maxTemperature"
            source="max_temperature"
            initialValue="37"
            {...getInputProps('max_temperature')}
          />
        )
      }
      {
        showDiagnosis && (
          <TextInput
            label="telemedic.users.form.chronicDiseases"
            source="chronic_diseases"
            multiline
            {...getInputProps('chronic_diseases')}
          />
        )
      }
      {
        showDiagnosis && (
          <TextInput
            label="telemedic.users.form.diagnosis"
            source="diagnosis"
            multiline
            {...getInputProps('diagnosis')}
          />
        )
      }
      <DeleteableFileInput
        accept="image/jpeg"
        deleteCheckboxLabel="telemedic.users.form.deletePhoto"
        fileInputLabel="telemedic.users.form.photo"
        source="photo"
        {...getInputProps('photo')}
      />
      <DeleteableFileInput
        accept="image/jpeg"
        deleteCheckboxLabel="telemedic.users.form.deleteMedCertificate"
        fileInputLabel="telemedic.users.form.medCertificate"
        source="med_certificate"
        {...getInputProps('med_certificate')}
      />
      <LargeCheckListInput
        label="telemedic.users.form.testQuestionCategories"
        choiceLabelField="category"
        choiceValueField="id"
        choices={testQuestionCategories}
        source="test_question_category_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('test_question_category_ids')}
      />
      <RemoveAuthPhotoCheckox />
      <BooleanInput
        label="telemedic.users.form.enabledEpl"
        source="enabled_epl"
        defaultValue={!rest.record?.id ? true : undefined}
        {...getInputProps('enabled_epl')}
      />
      <BooleanInput
        label="telemedic.users.form.itsDriver"
        source="its_driver"
        defaultValue={!rest.record?.id ? true : undefined}
        {...getInputProps('its_driver')}
      />
      <BooleanInput
        label="telemedic.users.form.isFired"
        source="is_fired"
        {...getInputProps('is_fired')}
      />
      <BooleanInput
        label="telemedic.users.form.isNotIncludedInWorkRestReport"
        source="is_not_included_in_work_rest_report"
        {...getInputProps('is_not_included_in_work_rest_report')}
      />
    </RestrictedSimpleForm>
  );
};

const organizationsDataPromise = (service) => service.getUserFormOrganizationValues();

const RemoveAuthPhotoCheckox = ({ record }) => {
  if (record.face_recognition_id) {
    return (
      <BooleanInput label="telemedic.users.form.removeAuthPhoto" source="remove_auth_photo" />
    );
  }
  return '';
};

export default UserForm;
