import React from 'react';
import { SimpleShowLayout, useShowController } from 'react-admin';

import FieldLink from '../../components/FieldLink';
import ListField from '../../components/fields/ListField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import LabeledTextField from '../../components/fields/LabeledTextField';
import FormatChipField from '../../components/fields/FormatChipField';
import TGNotificationsTitle from './TGNotificationsTitle';
import { RoutePath, getUrl } from '../../routes';
import { formatNotificationType } from '../../utils/formatters';


const getOrganizationUrl = (record) => (record ? getUrl(RoutePath.ORGANIZATION_SHOW, { id: record.id }) : '');

const organizationFormatter = (record) => (record ? `${record.id} - ${record.name}` : '');

export const TGNotificationsShow = (props) => {
  const { record } = useShowController(props);

  return (
    <RestrictedShow title={<TGNotificationsTitle />} {...props}>
      <SimpleShowLayout>
        <LabeledTextField
          label="telemedic.tgNotifications.show.worker"
          record={record}
          source="worker_id"
          url={getUrl(RoutePath.WORKER_SHOW, { id: record && record.worker_id })}
        />
        <ListField
          label="telemedic.tgNotifications.show.organizationsToNotify"
          source="organizations"
        >
          <FieldLink getUrl={getOrganizationUrl}>
            <FormatChipField formatter={organizationFormatter} clickable />
          </FieldLink>
        </ListField>
        <ListField
          label="telemedic.tgNotifications.show.notificationTypes"
          source="notification_types"
        >
          <FormatChipField formatter={formatNotificationType} />
        </ListField>
      </SimpleShowLayout>
    </RestrictedShow>
  );
}
