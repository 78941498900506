import React, { useCallback, useState } from 'react';
import {
  SelectInput, TextInput, BooleanInput, required,
} from 'react-admin';
import { FormSpy } from 'react-final-form';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import DeleteableFileInput from '../../components/inputs/DeleteableFileInput';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import { Role } from '../../constants';
import { getRole } from '../../storages/auth';
import { ValueTypes } from '../../utils/values';

const medOrganisationsDataPromise = (service) => service.getWorkerFormMedOrganizationValues();

const WorkerForm = ({ getInputProps, ...rest }) => {
  const role = getRole();
  const showRole = (!rest.record || !rest.record.id);
  const showLicense = [
    Role.ORG_DIRECTOR, Role.ADMIN, Role.MED_ORG_DIRECTOR, Role.SUPPORT,
  ].includes(role);
  const showEcp = [
    Role.ORG_DIRECTOR, Role.ADMIN, Role.MED_ORG_DIRECTOR, Role.SUPPORT,
    Role.PARTNER,
  ].includes(role);
  const showSignature = [
    Role.ORG_DIRECTOR, Role.ADMIN, Role.MED_ORG_DIRECTOR, Role.SUPPORT,
  ].includes(role);
  const allowTGNotificationSetting = [
    Role.ADMIN, Role.SUPPORT
  ].includes(role);
  const [values, setValues] = useState({});
  const onValuesChange = useCallback((form) => {
    setValues(form.values);
  }, [setValues]);
  const service = useTelemedicService();
  const roles = useTelemedicServiceJsonValue(
    () => service.getWorkerFormRoleValues(), null, [],
  );
  const organizations = useTelemedicServiceJsonValue(
    () => service.getWorkerFormOrganizationValues(), null, [],
  );
  const inspectionPoints = useTelemedicServiceJsonValue(
    (organizationIds) => service.getWorkerFormInspectionPointValues(organizationIds),
    values.visible_organization_ids, [],
  );
  return (
    <RestrictedSimpleForm {...rest}>
      <FormSpy
        subscription={{ values: true }}
        onChange={onValuesChange}
      />
      {showRole && (
        <SelectInput
          label="telemedic.workers.form.role"
          choices={roles}
          choiceLabelField="name"
          choiceValueField="id"
          source="role"
          validate={required()}
          {...getInputProps('role')}
        />
      )}
      <TextInput
        label="telemedic.workers.form.lastName"
        source="last_name"
        validate={required()}
        {...getInputProps('last_name')}
      />
      <TextInput
        label="telemedic.workers.form.firstName"
        source="first_name"
        validate={required()}
        {...getInputProps('first_name')}
      />
      <TextInput
        label="telemedic.workers.form.middleName"
        source="middle_name"
        validate={required()}
        {...getInputProps('middle_name')}
      />
      <TextInput
        label="telemedic.workers.form.login"
        source="login"
        validate={required()}
        {...getInputProps('login')}
      />
      <TextInput
        label="telemedic.workers.form.password"
        source="password"
        {...getInputProps('password')}
      />
      <TelemedicDataSource
        dataPromise={medOrganisationsDataPromise}
        dataDefault={[]}
        dataAttrName="choices"
      >
        <AutocompleteInput
          label="telemedic.workers.form.medOrganizations"
          choiceLabelField="name"
          choiceValueField="id"
          source="med_organization_id"
          fullWidth
          {...getInputProps('med_organization_id')}
        />
      </TelemedicDataSource>
      <BooleanInput
        label="telemedic.workers.form.isAllowedToAuthWithEsia"
        source="is_allowed_to_auth_with_esia"
        {...getInputProps('is_allowed_to_auth_with_esia')}
      />
      {
        allowTGNotificationSetting && (
          <BooleanInput
            label="telemedic.workers.form.isAllowedToReceiveTGNotifications"
            source="is_allowed_to_receive_tg_notifications"
            {...getInputProps('is_allowed_to_receive_tg_notifications')}
          />
        )
      }
      <TextInput
        label="telemedic.workers.form.esiaId"
        source="esia_id"
        {...getInputProps('esia_id')}
      />
      <LargeCheckListInput
        label="telemedic.workers.form.organizations"
        choiceLabelField="name"
        choiceValueField="id"
        choices={organizations}
        source="visible_organization_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('visible_organization_ids')}
      />
      <LargeCheckListInput
        label="telemedic.workers.form.inspectionPoints"
        choiceLabelField="name"
        choiceValueField="id"
        choices={inspectionPoints}
        source="inspection_point_ids"
        valueType={ValueTypes.INTEGER}
        {...getInputProps('inspection_point_ids')}
      />
      <TextInput
        label="telemedic.workers.form.snils"
        source="snils"
        {...getInputProps('snils')}
      />
      <TextInput
        label="telemedic.workers.form.phone"
        source="phone"
        {...getInputProps('phone')}
      />
      <TextInput
        label="telemedic.workers.form.email"
        source="email"
        {...getInputProps('email')}
      />
      <TextInput
        label="telemedic.workers.form.serial"
        source="serial"
        {...getInputProps('serial')}
      />
      <TextInput
        label="telemedic.workers.form.issuer"
        source="issuer"
        {...getInputProps('issuer')}
      />
      {showEcp && (
        <DateInput
          label="telemedic.workers.form.ecpDateFrom"
          source="ecp_date_from"
          {...getInputProps('ecp_date_from')}
        />
      )}
      {showEcp && (
        <DateInput
          label="telemedic.workers.form.ecpDateTo"
          source="ecp_date_to"
          {...getInputProps('ecp_date_to')}
        />
      )}
      {showEcp && (
        <TextInput
          label="telemedic.workers.form.ecp"
          source="ecp"
          multiline
          {...getInputProps('ecp')}
        />
      )}
      <DeleteableFileInput
        accept="image/*"
        deleteCheckboxLabel="telemedic.workers.form.deletePhoto"
        fileInputLabel="telemedic.workers.form.photo"
        source="photo"
        {...getInputProps('photo')}
      />
      {showLicense && (
        <DeleteableFileInput
          accept="image/*"
          deleteCheckboxLabel="telemedic.workers.form.deleteLicense"
          fileInputLabel="telemedic.workers.form.license"
          source="license_photo"
          {...getInputProps('license_photo')}
        />
      )}
      {showSignature && (
        <DeleteableFileInput
          accept="image/*"
          deleteCheckboxLabel="telemedic.workers.form.deleteSignature"
          fileInputLabel="telemedic.workers.form.signature"
          source="signature"
          {...getInputProps('signature')}
        />
      )}
      <BooleanInput
        label="telemedic.workers.form.isFired"
        source="is_fired"
        {...getInputProps('is_fired')}
      />
    </RestrictedSimpleForm>
  );
};

export default WorkerForm;
