import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { RoutePath } from '../../routes';

const LabeledTextField = ({ record, source, url, formatter, ...rest }) => {
  let formattedValue;
  if (formatter && record) {
    formattedValue = formatter(record[source]);
  }
  
  const value = source.split('.').reduce((acc, key) => acc?.[key], record);

  if (!value) return null;

  return (
    <Typography
      component="span"
      variant="body2"
      addLabel
      {...rest}
    >
      {url ? (
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {formattedValue || value}
        </Link>
      ) : (
        formattedValue || value
      )}
    </Typography>
  );
};

LabeledTextField.displayName = 'LabeledTextField';
LabeledTextField.defaultProps = {
  addLabel: true,
};
  


export default LabeledTextField;
